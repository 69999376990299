<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Subtipos de incidencias" :exportInfo="exportItems">
      <template v-slot:buttons>
        <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="dialogs.new = true" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idsubtipo_solicitud)">mdi-delete</v-icon></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`item.flujo_autorizaciones`]="{ item }">
          <div v-if="item.flujo_autorizaciones">
            <v-edit-dialog v-if="item.flujo_autorizaciones.data">
              Ver flujo
              <v-tooltip top
                ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" @click="previewFlow = item.flujo_autorizaciones.data.flujo_aprobacion" dark v-bind="attrs" v-on="on" :disabled="loading">mdi-sitemap</v-icon></template
                ><span>Ver flujo de {{ item.nombre }} </span></v-tooltip
              >
              <template v-slot:input>
                <v-card width="400">
                  <v-card-title class="primary white--text text-h5"> Flujo de autorizaciones </v-card-title>
                  <v-row class="pa-4" justify="space-between">
                    <v-col cols="12">
                      <v-treeview :active.sync="active" :items="previewFlow" activatable color="primary" item-text="nombre_completo" item-key="orden" open-on-click transition>
                        <template v-slot:prepend="{ item }">
                          <v-icon> mdi-numeric-{{ item.orden }}-circle-outline </v-icon>
                        </template>
                        <template v-slot:label="{ item }">
                          <span>
                            {{ item.nombre_completo }}
                          </span>
                        </template>
                      </v-treeview>
                    </v-col>
                    <v-divider></v-divider>
                    <v-col class="d-flex text-center">
                      <v-scroll-y-transition mode="out-in">
                        <div v-if="!active.length > 0" class="text-h6 grey--text text--lighten-1 font-weight-light" style="align-self: center">Seleccione un usuario</div>
                        <v-card v-else :key="previewUserFlow.orden" class="pt-6 mx-auto" flat width="400">
                          <v-card-text>
                            <v-avatar size="88" color="primary">
                              <span class="white--text text-h3">{{ previewUserFlow.nombre_completo ? previewUserFlow.nombre_completo[0] : "N" }}</span>
                            </v-avatar>
                            <h3 class="text-h5 mb-2">
                              {{ previewUserFlow.nombre_completo }}
                            </h3>
                            <div class="primary--text mb-2">
                              {{ previewUserFlow.email }}
                            </div>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-row class="text-h6 ma-0 text-left" tag="v-card-text">
                            <v-col class="text-right mr-4 mb-2" tag="strong" cols="5"> Origen: </v-col>
                            <v-col>{{ previewUserFlow.origen }}</v-col>
                            <v-col class="text-right mr-4 mb-2" tag="strong" cols="5"> Estatus: </v-col>
                            <v-col>{{ previewUserFlow.status }}</v-col>
                            <v-col class="text-right mr-4 mb-2" tag="strong" cols="5"> Orden: </v-col>
                            <v-col>{{ previewUserFlow.orden }}</v-col>
                          </v-row>
                        </v-card>
                      </v-scroll-y-transition>
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-edit-dialog>
            <span v-else> Flujo de aprobación no disponible </span>
          </div>
        </template>
        <template v-slot:[`item.fecha_creacion`]="{ item }">
          {{ dateFormat(item.fecha_creacion) }}
        </template>
        <template v-slot:[`item.fecha_actualizacion`]="{ item }">
          {{ dateFormat(item.fecha_creacion) }}
        </template>
        <template v-slot:[`item.administrador_aprueba`]="{ item }">
          {{ item.administrador_aprueba ? 'Si' : 'No' }}
        </template>
        <template v-slot:[`item.aplica_medio_dia`]="{ item }">
          {{ item.aplica_medio_dia ? 'Si' : 'No' }}
        </template>
        <template v-slot:[`item.es_solicitable`]="{ item }">
          {{ item.es_solicitable ? 'Si' : 'No' }}
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>

    <Dialog-Form :estate="dialogs.new" title="Nueva incidencia" maxWidth="1000px">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="save()" :loading="loading" />
      </template>
      <v-form ref="formNew" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="forms.new.nombre" label="Nombre de subtipo" prepend-icon="mdi-account-group" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-8">
            <v-autocomplete v-model="forms.new.tipo_solicitud" :items="types" item-text="nombre" item-value="idtipo_solicitud" label="Tipo de incidencia" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete v-model="forms.new.administrador_aprueba" :items="activeData" item-text="text" item-value="value" label="Aprobación de administrador" prepend-icon="mdi-account-tie" persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete v-model="forms.new.aplica_medio_dia" :items="activeData" item-text="text" item-value="value" label="Medio dia" prepend-icon="mdi-clipboard-text-clock-outline" persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete v-model="forms.new.es_solicitable" :items="activeData" item-text="text" item-value="value" label="Solicitable" prepend-icon="mdi-clipboard-check-outline" persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-data-table :headers="headersFlow" :expanded.sync="expandedReference" :single-expand="true" item-key="orden" show-expand :items="forms.new.data.flujo_aprobacion" :key="detectChange" hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <Basic-Btn text="Agregar usuario" color="primary" icon="mdi-plus" @click="addItem('new')" :loading="loading" :disabled="forms.new.data.flujo_aprobacion.length == 3" />
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top
                  ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="lessItem(item, 'new')">mdi-delete</v-icon></template
                  ><span>Eliminar</span></v-tooltip
                >
              </template>
              <template v-slot:[`item.orden`]="{ item }">
                <v-tooltip top
                  ><template v-slot:activator="{ on, attrs }"><v-icon class="pa-0 ma-0" size="22" v-bind="attrs" v-on="on" color="primary" :disabled="item.orden == 3" @click="orderItem('new', item.orden, 'down')">mdi-arrow-down-bold</v-icon></template
                  ><span>Bajar a orden {{ item.orden + 1 }}</span></v-tooltip
                >
                <span class="pa-0 ma-0">
                  {{ item.orden }}
                </span>
                <v-tooltip top
                  ><template v-slot:activator="{ on, attrs }"><v-icon class="pa-0 ma-0" size="22" v-bind="attrs" v-on="on" color="primary" :disabled="item.orden == 1" @click="orderItem('new', item.orden, 'up')">mdi-arrow-up-bold</v-icon></template
                  ><span>Subir a orden {{ item.orden - 1 }}</span></v-tooltip
                >
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div class="pt-6 pb-0">
                    <v-row>
                      <v-col cols="2">
                        <v-checkbox v-model="configVar" label="Catalogo de aprobadores"></v-checkbox>
                      </v-col>
                      <v-col cols="5" v-if="configVar">
                        <v-autocomplete v-model="item.idconfiguracion" :items="settings" @change="changeConfigure('new', item.idconfiguracion, item.orden)" item-text="valores.alias" item-value="idconfiguracion" label="Catalogo de aprobadores" prepend-icon="mdi-sitemap" persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
                      </v-col>
                      <v-col :cols="configVar ? 5 : 10">
                        <v-autocomplete v-model="item.idusuario" @change="changeUser('new', item.idusuario, item.orden)" :items="users" item-text="nombre_completo" item-value="idusuario" label="Encargado" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" :disabled="configVar" hide-details dense outlined></v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>
                  <br />
                </td>
              </template>

              <template v-slot:no-data>
                <Basic-Btn text="Agregar usuario" color="primary" icon="mdi-plus" @click="addItem('new')" :loading="loading" />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.update" title="Actualizar incidencia" maxWidth="1000px">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="update()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="forms.update.nombre" label="Nombre de subtipo" prepend-icon="mdi-account-group" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-8">
            <v-autocomplete v-model="forms.update.tipo_solicitud" :items="types" item-text="nombre" item-value="idtipo_solicitud" label="Tipo de incidencia" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete v-model="forms.update.administrador_aprueba" :items="activeData" item-text="text" item-value="value" label="Aprobación de administrador" prepend-icon="mdi-account-tie" persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete v-model="forms.update.aplica_medio_dia" :items="activeData" item-text="text" item-value="value" label="Medio dia" prepend-icon="mdi-clipboard-text-clock-outline" persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete v-model="forms.update.es_solicitable" :items="activeData" item-text="text" item-value="value" label="Solicitable" prepend-icon="mdi-clipboard-check-outline" persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-data-table :headers="headersFlow" :expanded.sync="expandedReference" :single-expand="true" item-key="orden" show-expand :items="forms.update.data.flujo_aprobacion" :key="detectChange" hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <Basic-Btn text="Agregar usuario" color="primary" icon="mdi-plus" @click="addItem('update')" :loading="loading" :disabled="forms.update.data.flujo_aprobacion.length == 3" />
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top
                  ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="lessItem(item, 'update')">mdi-delete</v-icon></template
                  ><span>Eliminar</span></v-tooltip
                >
              </template>
              <template v-slot:[`item.orden`]="{ item }">
                <v-tooltip top
                  ><template v-slot:activator="{ on, attrs }"><v-icon class="pa-0 ma-0" size="22" v-bind="attrs" v-on="on" color="primary" :disabled="item.orden == 3" @click="orderItem('update', item.orden, 'down')">mdi-arrow-down-bold</v-icon></template
                  ><span>Bajar a orden {{ item.orden + 1 }}</span></v-tooltip
                >
                <span class="pa-0 ma-0">
                  {{ item.orden }}
                </span>
                <v-tooltip top
                  ><template v-slot:activator="{ on, attrs }"><v-icon class="pa-0 ma-0" size="22" v-bind="attrs" v-on="on" color="primary" :disabled="item.orden == 1" @click="orderItem('update', item.orden, 'up')">mdi-arrow-up-bold</v-icon></template
                  ><span>Subir a orden {{ item.orden - 1 }}</span></v-tooltip
                >
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div class="pt-6 pb-0">
                    <v-row>
                      <v-col cols="2">
                        <v-checkbox v-model="configVar" label="Catalogo de aprobadores"></v-checkbox>
                      </v-col>
                      <v-col cols="5" v-if="configVar">
                        <v-autocomplete v-model="item.idconfiguracion" :items="settings" @change="changeConfigure('update', item.idconfiguracion, item.orden)" item-text="valores.alias" item-value="idconfiguracion" label="Catalogo de aprobadores" prepend-icon="mdi-sitemap" persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
                      </v-col>
                      <v-col :cols="configVar ? 5 : 10">
                        <v-autocomplete v-model="item.idusuario" @change="changeUser('update', item.idusuario, item.orden)" :items="users" item-text="nombre_completo" item-value="idusuario" label="Encargado" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" :disabled="configVar" hide-details dense outlined></v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>
                  <br />
                </td>
              </template>

              <template v-slot:no-data>
                <Basic-Btn text="Agregar usuario" color="primary" icon="mdi-plus" @click="addItem('update')" :loading="loading" />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>

    <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea eliminar la solicitud?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Eliminar" color="error" icon="mdi-check" @click="remove()" :loading="loading" />
      </template>
    </Delete-Message>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage } from "@/components";
import moment from "moment";
import _ from "lodash";

export default {
  name: "group",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
  },
  data: () => ({
    loading: false,
    configVar: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    detectChange: 0,
    previewUserFlow: {},
    previewFlow: [],
    active: [],
    open: [],
    expandedReference: [],
    headers: [
      { text: "ID", align: "left", sortable: true, value: "idsubtipo_solicitud" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre" },
      { text: "Tipo de incidencia", align: "left", sortable: true, value: "tipo_solicitud.nombre" },
      { text: "Aprueba el administrador", align: "left", sortable: true, value: "administrador_aprueba" },
      { text: "Medio dia", align: "left", sortable: true, value: "aplica_medio_dia" },
      { text: "Solicitable", align: "left", sortable: true, value: "es_solicitable" },
      { text: "Fecha de creación", align: "left", sortable: true, value: "fecha_creacion" },
      { text: "Fecha de actualización", align: "left", sortable: true, value: "fecha_actualizacion" },
      { text: "Flujo de aprobación", align: "left", sortable: true, value: "flujo_autorizaciones" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    headersFlow: [
      { text: "Orden", align: "left", sortable: true, value: "orden" },
      { text: "Configuración", align: "left", sortable: true, value: "idconfiguracion" },
      { text: "Encargado", align: "left", sortable: false, value: "nombre_completo" },
      { text: "Correo de encargado", align: "left", sortable: false, value: "email" },
      { text: "Acciones", align: "center", sortable: false, value: "actions" },
      { text: "Expandir", align: "center", value: "data-table-expand" },
    ],
    items: [],
    types: [],
    selected: [],
    users: [],
    settings: [],
    deleted: null,
    activeData:[
      {
        value: true,
        text: 'Si',
      },
      {
        value: false,
        text: 'No',
      },
    ],
    forms: {
      new: {
        nombre: "",
        idtipo_solicitud: "",
        data: {
          flujo_aprobacion: [],
        },
        administrador_aprueba: true,
        aplica_medio_dia: true,
        es_solicitable: true,
      },
      update: {
        idtipo_solicitud: "",
        nombre: "",
        data: {
          flujo_aprobacion: [],
        },
        administrador_aprueba: true,
        aplica_medio_dia: true,
        es_solicitable: true,
      },
    },
    rules: {
      nombre: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 50) || "El campo excede la longitud máxima"],
    },
    dialogs: {
      new: false,
      update: false,
      remove: false,
    },
  }),
  computed: {
    exportItems() {
      let array = [];
      for (let data of this.items) {
        array.push({
          id: data.idsubtipo_solicitud ? data.idsubtipo_solicitud : "",
          nombre: data.nombre ? data.nombre : "",
          tipo_solicitud: data.tipo_solicitud ? data.tipo_solicitud.nombre : "",
          fecha_actualizacion: data.fecha_creacion ? this.dateFormat(data.fecha_actualizacion) : "",
          fecha_creacion: data.fecha_creacion ? this.dateFormat(data.fecha_creacion) : "",
        });
      }
      return array;
    },
  },
  mounted() {
    this.refresh();
    this.getTypes();
    this.getUser();
    this.getSettings();
  },
  watch: {
    active(state) {
      this.previewUserFlow = this.previewFlow[state - 1];
    },
  },
  methods: {
    async refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.requestSubtype)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    async getSettings() {
      this.loading = true;
      this.axios
        .get(services.routes.setting)
        .then((response) => {
          this.settings = _.filter(response.data.data, function (o) {
            return o.valores.alias;
          });
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    getTypes() {
      this.loading = true;
      this.axios
        .get(services.routes.requestType)
        .then((response) => {
          this.types = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        this.forms.new = {
        nombre: "",
        idtipo_solicitud: "",
        data: {
          flujo_aprobacion: [],
        },
      };
        this.dialogs.new = false;
      } else if (this.dialogs.update) {
        this.dialogs.update = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      }
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.new));
        this.axios
          .post(services.routes.requestSubtype, { data })
          .then(() => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Tipo de incidencia creado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    view(data) {
      this.forms.update = JSON.parse(JSON.stringify(data));
      this.forms.update.data = data.flujo_autorizaciones ? JSON.parse(JSON.stringify(data.flujo_autorizaciones.data ? data.flujo_autorizaciones.data : { flujo_aprobacion: [] })) : { flujo_aprobacion: [] };
      this.forms.update.tipo_solicitud = data.tipo_solicitud ? data.tipo_solicitud.idtipo_solicitud :  0;
      this.dialogs.update = true;
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.requestSubtype, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Tipo de incidencia actualizada exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        this.axios
          .delete(services.routes.requestSubtype, { data: { data: { idsubtipo_solicitud: this.deleted } } })
          .then(() => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Tipo de incidencia eliminado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    addItem(type) {
      if (this.forms[type].data ? this.forms[type].data.flujo_aprobacion.length < 3 : false) {
        this.forms[type].data.flujo_aprobacion.push({
          email: "",
          orden: this.forms[type].data.flujo_aprobacion.length + 1,
          origen: "ADMIN",
          status: "Pendiente",
          idusuario: 0,
          nombre_completo: "",
        });
        this.detectChange += 1;
      }
    },
    lessItem(item, type) {
      this.forms[type].data.flujo_aprobacion.splice(this.forms[type].data.flujo_aprobacion.indexOf(item), 1);
      for (let i = 0; i < this.forms[type].data.flujo_aprobacion.length; i++) {
        if (this.forms[type].data.flujo_aprobacion[i].orden != i + 1) {
          this.forms[type].data.flujo_aprobacion[i].orden = i + 1;
        }
      }
      this.detectChange += 1;
    },
    async getUser() {
      this.loading = true;
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.users = response.data.data;
          for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].fecha_borrado) {
              this.users.splice(i, 1);
              i--;
            }
          }
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].nombre_completo = (this.users[i].detalles_usuarios[0].nombre ? " " + this.users[i].detalles_usuarios[0].nombre : "") + (this.users[i].detalles_usuarios[0].apellido_paterno ? " " + this.users[i].detalles_usuarios[0].apellido_paterno : "") + (this.users[i].detalles_usuarios[0].apellido_materno ? " " + this.users[i].detalles_usuarios[0].apellido_materno : "");
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeUser(type, user, id) {
      let aux = _.filter(this.users, function (o) {
        return user == o.idusuario;
      });
      this.forms[type].data.flujo_aprobacion[id - 1].email = aux[0].email;
      this.forms[type].data.flujo_aprobacion[id - 1].nombre_completo = aux[0].nombre_completo;
      if (this.forms[type].data.flujo_aprobacion[id - 1].idconfiguracion) {
        delete this.forms[type].data.flujo_aprobacion[id - 1].idconfiguracion;
        this.forms[type].data.flujo_aprobacion[id - 1].origen = "ADMIN";
      }
    },
    orderItem(type, id, position) {
      let newPlace = position == "up" ? id - 1 : id + 1;
      let copy = JSON.parse(JSON.stringify(this.forms[type].data.flujo_aprobacion));
      copy[newPlace - 1].orden = id;
      copy[id - 1].orden = newPlace;
      this.forms[type].data.flujo_aprobacion[id - 1] = copy[newPlace - 1];
      this.forms[type].data.flujo_aprobacion[newPlace - 1] = copy[id - 1];
      this.detectChange += 1;
    },
    changeConfigure(type, idconfig, id) {
      let selectConfigure = _.filter(this.settings, function (o) {
        return idconfig == o.idconfiguracion;
      });
      let user_name = _.filter(this.users, function (o) {
        return selectConfigure[0].valores.idusuario == o.idusuario;
      }); 
      this.forms[type].data.flujo_aprobacion[id - 1].idusuario = selectConfigure[0].valores.idusuario;
      this.forms[type].data.flujo_aprobacion[id - 1].nombre_completo = user_name[0].nombre_completo;
      this.forms[type].data.flujo_aprobacion[id - 1].email = user_name[0].email;
      this.forms[type].data.flujo_aprobacion[id - 1].origen = this.forms[type].data.flujo_aprobacion[id - 1].idconfiguracion  ? "CONFIGURACION" : "ADMIN";
      if(this.forms[type].data.flujo_aprobacion[id - 1].idconfiguracion){
      this.forms[type].data.flujo_aprobacion[id - 1].idconfiguracion = this.forms[type].data.flujo_aprobacion[id - 1].idconfiguracion;
}else{
  delete this.forms[type].data.flujo_aprobacion[id - 1].idconfiguracion
}
      this.detectChange += 1;
    },
  },
};
</script>

<style scoped></style>
